import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import CreateIcon from "@mui/icons-material/Create";
import HistoryIcon from "@mui/icons-material/History";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { deleteArea, getAreas } from "../../store/areasSlice";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { Link } from "react-router-dom";
import { deleteDitrict, getDitricts } from "../../store/districtsSlice";
import {
  deleteBuilding,
  downloadBuildingData,
  getBuildings,
  getImageBuilding,
} from "../../store/buildingSlice";
import TablePaginaiton from "../../components/TablePaginaiton";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import HistoryDialog from "../../components/HistoryDialog";
import { getHistory } from "../../store/historySlice";
import BuidingDetailsDialog from "../../components/BuidingDetailsDialog";
import MapDialog from "../../components/MapDialog";
import MapDialogforImplemented from "../../components/MapDialogforImplemented";

function BuilsingList() {
  const dispatch = useDispatch();
  const buildings = useSelector((state) => state.buildingsSlice.buildings);
  const [buildingDetials, setBuildingDetails] = useState("");
  const [mapPoints, setMapPoints] = useState({});
  const [mapPointsImp, setMapPointsImp] = useState({});
  const historiews = useSelector((state) => state.historySlice.history);
  const [loading, setLoading] = useState();
  const areas = useSelector((state) => state.areasSlice.areas);
  const ditricts = useSelector((state) => state.ditrictsSlice.ditricts);
  const totlepages = useSelector((state) => state.buildingsSlice.pagesnumber);
  const [isLoading, setIsLoading] = useState(true);
  const [coordinatesArray, setCoordinatesArray] = useState([]);
  const [page, setPage] = useState(1);
  // console.log(buildings);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [openMapoDialog, setOpenMapDialog] = React.useState(false);
  const [openMapoDialogImp, setOpenMapDialogImp] = React.useState(false);
  const [filteriation, setFilterationInput] = useState({
    post_code: "",
    status: "",
    implemented_date_from: "",
    implemented_date_to: "",
  });

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  } else if (role_id == "4") {
    roleName = "tm";
  } else if (role_id == "5") {
    roleName = "tm";
  } else if (role_id == "6") {
    roleName = "monitor";
  }

  useEffect(() => {
    setLoading(true);
    dispatch(
      getBuildings({
        pageNumber: page,
        status: filteriation.status,
        implemented_date_from: filteriation.implemented_date_from,
        implemented_date_to: filteriation.implemented_date_to,
        post_code: filteriation.post_code,
      })
    )
      .unwrap()
      .then((data) => {
        const newcoordinatesArray = data.data.map((item) => ({
          lat: item.latitude,
          lng: item.longitude,
        }));
        // console.log(data);
        setCoordinatesArray(newcoordinatesArray);
        setIsLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsLoading(false);
        setLoading(false);
      });
    dispatch(getAreas({ pageNumber: "" }));
    // dispatch(getDitricts({ pageNumber: 0, dhId: "", pmId: "", arId: "" })).unwrap().then((data)=>{
    //   console.log(data);
    //   setLoading(false);
    // }).catch((error)=>{
    //   toast.error(error.message);
    // });
  }, [dispatch, page]);

  const handledelete = (id) => {
    // Logic to handle editing the row with the given id
    // console.log(`Editing row with ID: ${id}`);

    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(deleteBuilding(id))
              .unwrap()
              .then((data) => {
                setLoading(false);
                toast.success("تم المسح بنجاح");
              })
              .catch((error) => {
                setLoading(false);
                toast.error(error.message);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const handelHistoryDialog = (placeId) => {
    setLoading(true);
    dispatch(getHistory({ place: "building", placeId: placeId }))
      .unwrap()
      .then((data) => {
        setOpenDialog(true);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  const handelInfoDialog = (value) => {
    // var myfiler = buildings.filter((build) => build.id === value);
    setLoading(true);
    console.log("hi");
    dispatch(getImageBuilding(value))
      .unwrap()
      .then((data) => {
        console.log(data);
        setBuildingDetails(data.data);
        setLoading(false);
        setOpenInfoDialog(true);
      });
  };

  // handelMapPointsDialog
  const handelMapPointsDialog = (value) => {
    var myfiler = buildings.filter((build) => build.id === value);
    // console.log(myfiler);
    // console.log(myfiler);
    setMapPoints({
      lat: myfiler[0].latitude,
      lng: myfiler[0].longitude,
      status: myfiler[0].status,
      building_code: myfiler[0].building_code,
      implementing_lang: myfiler[0].implementing_lang,
      implementing_lat: myfiler[0].implementing_lat,
    });
    setOpenMapDialog(true);
  };

  // handelMapPointsDialogImp
  const handelMapPointsDialogImp = (value) => {
    var myfiler = buildings.filter((build) => build.id === value);
    // console.log(myfiler);
    // console.log(myfiler);
    setMapPointsImp({
      lat: myfiler[0].latitude,
      lng: myfiler[0].longitude,
      status: myfiler[0].status,
      building_code: myfiler[0].building_code,
      implementing_lang: myfiler[0].implementing_lang,
      implementing_lat: myfiler[0].implementing_lat,
    });
    setOpenMapDialogImp(true);
  };

  const coulmns = [
    {
      field: "image",
      headerName: "image",
      width: 70,
      renderCell: (params) => (
        <div className="tableoptions">
          <div className="table_img">
            <div className="card_img">
              <div className="img_parent">
                <img src={params.row?.media?.image_link} alt="" />
              </div>
            </div>
          </div>
        </div>
      ),
    },

    { field: "id", headerName: "ID", width: 70 },
    // {
    //   field: "building_code",
    //   headerName: "رقم اللوحه",
    //   editable: false,
    //   width: 150,
    // },
    // { field: "longitude", headerName: "الطول", editable: true, width: 150 },
    // { field: "latitude", headerName: "العرض", editable: true, width: 150 },
    {
      field: "post_code",
      headerName: "الرمز البريدي",
      editable: false,
      width: 150,
    },
    {
      field: "status",
      headerName: "الحاله",
      editable: false,
      width: 150,
      valueGetter: (params) => {
        const statusValue = params.row.status;
        if (statusValue === "1") {
          return "قيد الانتظار";
        } else if (statusValue === "3") {
          return "تم التركيب";
        } else {
          return "Unknown"; // You can set a default label for other values if needed
        }
      },
      cellClassName: (params) => {
        if (params.value === "قيد الانتظار") {
          // Add your CSS class for background and color styles here
          return "pending-status";
        }
        if (params.value === "تم التركيب") {
          // Add your CSS class for background and color styles here
          return "done-status";
        }
        // You can return other classes or leave it empty for default styling
        return "";
      },
    },
    {
      field: "implementation_note",
      headerName: "ملاحظة التنفيذ",
      editable: false,
      width: 150,
    },
    // { field: "qr", headerName: "الباركود", editable: true, width: 250 },
  ];

  if (role_id == "4") {
    coulmns.push({
      field: "assigned_date",
      headerName: "تاريخ الاستلام",
      width: 150,
      renderCell: (params) => (
        <div className="tableoptions">
          {params.row?.assigned_date != null
            ? params.row?.assigned_date?.split(" ")[0]
            : ""}
        </div>
      ),
    });
  }

  if (role_id == "4") {
    coulmns.push(
      {
        field: "implemented_date",
        headerName: "تاريخ التركيب",
        width: 100,
        renderCell: (params) => (
          <div className="tableoptions">
            {params.row?.implemented_date != null
              ? params.row?.implemented_date?.split(" ")[0]
              : ""}
          </div>
        ),
      },
    );
  }

  if (role_id == "4") {
    coulmns.push({
      field: "actions",
      width: 150,
      headerName: "موقع التركيب",
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="infoicon">
            <RoomOutlinedIcon
              onClick={() => handelMapPointsDialog(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    });
  }

  // if (role_id !== "4" && role_id !== "5") {
  //   coulmns.push({
  //     field: "is_assigned",
  //     headerName: "هل موزعه",
  //     editable: true,
  //   });
  // }

  if (
    role_id !== "4" &&
    role_id !== "5" &&
    role_id !== "2" &&
    role_id !== "3" &&
    role_id !== "6"
  ) {
    coulmns.push({
      field: "actions",
      width: 150,
      headerName: "Actions",
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="infoicon">
            <InfoOutlinedIcon
              onClick={() => handelInfoDialog(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
          <span className="historyicon">
            <HistoryIcon
              onClick={() => handelHistoryDialog(params.row.id)}
            ></HistoryIcon>
          </span>
          <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    });
  }

  if (role_id == "3") {
    coulmns.push(
      {
        field: "implementation_supervisor_data",
        width: 150,
        headerName: "المشرف",
      },
      {
        field: "actions1",
        width: 100,
        headerName: "موقع المبنى",
        renderCell: (params) => (
          <div className="tableoptions">
            <span className="infoicon">
              <RoomOutlinedIcon
                onClick={() => handelMapPointsDialog(params.row.id)}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
        ),
      },
      {
        field: "actions2",
        width: 100,
        headerName: "موقع التركيب",
        renderCell: (params) => (
          <div className="tableoptions">
            <span className="infoicon">
              <RoomOutlinedIcon
                onClick={() => handelMapPointsDialogImp(params.row.id)}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
        ),
      },
      {
        field: "implemented_date",
        headerName: "تاريخ التركيب",
        width: 100,
        renderCell: (params) => (
          <div className="tableoptions">
            {params.row?.implemented_date != null
              ? params.row?.implemented_date?.split(" ")[0]
              : ""}
          </div>
        ),
      },
      {
        field: "actions",
        width: 150,
        headerName: "Actions",
        renderCell: (params) => (
          <div className="tableoptions">
            <span className="infoicon">
              <InfoOutlinedIcon
                onClick={() => handelInfoDialog(params.row.id)}
                style={{ cursor: "pointer" }}
              />
            </span>
            <span className="historyicon">
              <HistoryIcon
                onClick={() => handelHistoryDialog(params.row.id)}
              ></HistoryIcon>
            </span>
          </div>
        ),
      }
    );
  }

  if (role_id == "6") {

    coulmns.splice(1, 1)
    coulmns.splice(0, 1)

    coulmns.push(
      {
        field: "actions1",
        width: 100,
        headerName: "موقع المبنى",
        renderCell: (params) => (
          <div className="tableoptions">
            <span className="infoicon">
              <RoomOutlinedIcon
                onClick={() => handelMapPointsDialog(params.row.id)}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
        ),
      },
      // {
      //   field: "actions2",
      //   width: 100,
      //   headerName: "موقع التركيب",
      //   renderCell: (params) => (
      //     <div className="tableoptions">
      //       <span className="infoicon">
      //         <RoomOutlinedIcon
      //           onClick={() => handelMapPointsDialogImp(params.row.id)}
      //           style={{ cursor: "pointer" }}
      //         />
      //       </span>
      //     </div>
      //   ),
      // },
      {
        field: "implemented_date",
        headerName: "تاريخ التركيب",
        width: 100,
        renderCell: (params) => (
          <div className="tableoptions">
            {params.row?.implemented_date != null
              ? params.row?.implemented_date?.split(" ")[0]
              : ""}
          </div>
        ),
      },
      {
        field: "image",
        headerName: "image",
        width: 70,
        renderCell: (params) => (
          <div className="tableoptions">
            <div className="table_img">
              <div className="card_img" onClick={() => handelInfoDialog(params.row.id)} style={{cursor: "pointer"}}>
                <div className="img_parent">
                  <img src={params.row?.media?.image_link} alt="" />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        field: "actions",
        width: 150,
        headerName: "Actions",
        renderCell: (params) => (
          <div className="tableoptions">
            {/* <span className="infoicon">
              <InfoOutlinedIcon
                onClick={() => handelInfoDialog(params.row.id)}
                style={{ cursor: "pointer" }}
              />
            </span> */}
            <span className="historyicon">
              <HistoryIcon
                onClick={() => handelHistoryDialog(params.row.id)}
              ></HistoryIcon>
            </span>
          </div>
        ),
      }
    );
  }

  if (role_id == "2") {
    coulmns.push(
      {
        field: "implementation_supervisor_data",
        width: 150,
        headerName: "المشرف",
      },
      {
        field: "actions1",
        width: 100,
        headerName: "موقع المبنى",
        renderCell: (params) => (
          <div className="tableoptions">
            <span className="infoicon">
              <RoomOutlinedIcon
                onClick={() => handelMapPointsDialog(params.row.id)}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
        ),
      },
      {
        field: "actions2",
        width: 100,
        headerName: "موقع التركيب",
        renderCell: (params) => (
          <div className="tableoptions">
            <span className="infoicon">
              <RoomOutlinedIcon
                onClick={() => handelMapPointsDialogImp(params.row.id)}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
        ),
      },
      {
        field: "implemented_date",
        headerName: "تاريخ التركيب",
        width: 100,
        renderCell: (params) => (
          <div className="tableoptions">
            {params.row?.implemented_date != null
              ? params.row?.implemented_date?.split(" ")[0]
              : ""}
          </div>
        ),
      },
      {
        field: "actions",
        width: 150,
        headerName: "Actions",
        renderCell: (params) => (
          <div className="tableoptions">
            <span className="infoicon">
              <InfoOutlinedIcon
                onClick={() => handelInfoDialog(params.row.id)}
                style={{ cursor: "pointer" }}
              />
            </span>
            <span className="historyicon">
              <HistoryIcon
                onClick={() => handelHistoryDialog(params.row.id)}
              ></HistoryIcon>
            </span>
            <span className="editicon">
              <Link to={`/pm/edit-building-status/${params.row.id}`}>
                <EditIcon style={{ cursor: "pointer" }} />
              </Link>
            </span>
          </div>
        ),
      }
    );
  }

  // if (
  //   role_id == "4"
  // ) {
  //   coulmns.push({
  //     field: "actions",
  //     width: 150,
  //     headerName: "Actions",
  //     renderCell: (params) => (
  //       <div className="tableoptions">
  //         <Link to={`/tm/showmapIcons`} className="infoicon">
  //           <RoomOutlinedIcon
  //             style={{ cursor: "pointer" }}
  //           />
  //         </Link>
  //       </div>
  //     ),
  //   });
  // }

  const handlepageChange = (event, value) => {
    setPage(value);
    // console.log(value);
  };

  const handelFilteration = (e) => {
    const { name, value } = e.target;
    setFilterationInput((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const filterBuildings = (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(
      getBuildings({
        pageNumber: 1,
        status: filteriation.status,
        implemented_date_from: filteriation.implemented_date_from,
        implemented_date_to: filteriation.implemented_date_to,
        post_code: filteriation.post_code,
        implementation_note: filteriation.implementation_note
      })
    )
      .unwrap()
      .then((res) => {
        // console.log(res);
        setLoading(false);
        setPage(1);
      });
  };

  const resetFilteration = (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(
      getBuildings({
        pageNumber: 1,
        status: "",
        implemented_date: "",
        post_code: "",
        implementation_note: ""
      })
    )
      .unwrap()
      .then((res) => {
        console.log(res);
        setFilterationInput({
          status: "",
          implemented_date: "",
          post_code: "",
        });
        setLoading(false);
      });
  };

  const downloadBuildingDataBtn = (e) => {
    setLoading(true);
    e.preventDefault();
    dispatch(
      downloadBuildingData({
        pageNumber: "",
        status: filteriation.status,
        implemented_date_from: filteriation.implemented_date_from,
        implemented_date_to: filteriation.implemented_date_to,
        post_code: filteriation.post_code,
        implementation_note:filteriation.implementation_note
      })
    )
      .unwrap()
      .then((res) => {
        // console.log(res.data);
        const link = document.createElement("a");
        link.href = res.data;
        link.download = "building.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        console.log(err);
        setLoading(false);
      });
  };

  if (isLoading) return <Loading open={true} />;

  return (
    <>
      {loading ? <Loading open={true} /> : null}

      <>
        <HistoryDialog
          historiews={historiews}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handleClickOpen={handleClickOpen}
        />

        <MapDialog
          mapPoints={mapPoints}
          openMapoDialog={openMapoDialog}
          setOpenMapDialog={setOpenMapDialog}
        />

        <MapDialogforImplemented
          mapPointsImp={mapPointsImp}
          openMapoDialogImp={openMapoDialogImp}
          setOpenMapDialogImp={setOpenMapDialogImp}
        />

        {buildingDetials == "" ? (
          ""
        ) : (
          <BuidingDetailsDialog
            buildingDetials={buildingDetials}
            setBuildingDetails={setBuildingDetails}
            setOpenInfoDialog={setOpenInfoDialog}
            openInfoDialog={openInfoDialog}
          />
        )}

        <div className="manage_employee plates_list building_table_page custom_pagination_table2">
          {role_id == "3" || role_id == "4" || role_id == "6" ? (
            ""
          ) : (
            <div className="add_button">
              <Link to={`/${roleName}/add-bulk-buildings`}>
                اضافة ملف باللوحات
              </Link>
            </div>
          )}

          <Grid container spacing={8} className="algin-center">
            <Grid sm={12} xs={12} md={11}>
              <div className="table_show table_style">
                <h5>قائمة اللوحات</h5>
                <button href="" className="showall" onClick={resetFilteration}>
                  عرض الكل
                </button>
                <div className="filter">
                  <h5>تصفيه</h5>
                  <form action="" style={{ marginBottom: "30px" }}>
                    <div className="form-group">
                      <label htmlFor="">الحاله</label>
                      <select
                        name="status"
                        value={filteriation.status}
                        onChange={handelFilteration}
                        id=""
                        className="form-control"
                        style={{ width: "90%" }}
                      >
                        <option defaultValue="0">اختر الحاله</option>
                        <option value="3">تم التركيب</option>
                        <option value="1">قيد الانتظار</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="">تاريخ بدايه التركيب</label>
                      <input
                        type="date"
                        name="implemented_date_from"
                        value={filteriation.implemented_date_from}
                        className="form-control"
                        onChange={handelFilteration}
                        style={{ width: "90%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">تاريخ نهايه التركيب</label>
                      <input
                        type="date"
                        name="implemented_date_to"
                        value={filteriation.implemented_date_to}
                        className="form-control"
                        onChange={handelFilteration}
                        style={{ width: "90%" }}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="">الرمز البريدي</label>
                      <input
                        value={filteriation.post_code}
                        type="text"
                        name="post_code"
                        style={{ width: "90%" }}
                        className="form-control"
                        onChange={handelFilteration}
                        placeholder="22.33.213"
                      />
                    </div>

                    <div className="btn_submit">
                      <label htmlFor="">.</label>
                      <button className="btn" onClick={filterBuildings}>
                        بحث
                      </button>
                    </div>
                  </form>
                </div>

                {role_id == "4" ? (
                  <div className="btn_submit">
                    <Link className="exportcsv" to="/tm/map-polygan">
                      عرض المنطقه على الخريطه
                    </Link>
                  </div>
                ) : null}

                <button className="exportcsv" onClick={downloadBuildingDataBtn}>
                  تصدير كملف CSV
                </button>

                <DataGridDemo
                  coulmns={coulmns}
                  buldcom={true}
                  rows={buildings}
                  showFilter={true}
                />

                <TablePaginaiton
                  totlepages={totlepages}
                  page={page}
                  setPage={setPage}
                  handlepageChange={handlepageChange}
                  className="custom_pagination_table"
                />
              </div>
            </Grid>
            <Grid xs={3}>
              {/* <div className="options_buttons">
              <ul className="list-unstyled">
                <li>
                  <a href="">الاحياء الموزعه</a>
                </li>
                <li>
                  <a href="">الاحياء الغير موزعه</a>
                </li>
              </ul>
            </div> */}
            </Grid>
          </Grid>
        </div>
      </>
    </>
  );
}

export default BuilsingList;
