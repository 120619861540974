import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import TextField from "@mui/material/TextField";
import { Container } from "@mui/material";
import DataGridDemo from "../../components/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import CreateIcon from "@mui/icons-material/Create";
import HistoryIcon from "@mui/icons-material/History";
import { useDispatch, useSelector } from "react-redux";
import { deleteArea, getAreas } from "../../store/areasSlice";
import { Link } from "react-router-dom";
import { deleteDitrict, getDitricts } from "../../store/districtsSlice";
import TablePaginaiton from "../../components/TablePaginaiton";
import Loading from "../../components/Loading";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { toast } from "react-toastify";
import { getDhUsers, getPmUsers } from "../../store/usersLists";
import { getTeamByDh, getTeamByDhFilter } from "../../store/teamSlice";
import { getHistory } from "../../store/historySlice";
import HistoryDialog from "../../components/HistoryDialog";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";

function DistrictsList() {
  const dispatch = useDispatch();
  const historiews = useSelector((state) => state.historySlice.history);
  const ditricts = useSelector((state) => state.ditrictsSlice.ditricts);
  const totlepages = useSelector((state) => state.ditrictsSlice.pagesnumber);
  const pmusers = useSelector((state) => state.usersListSlice.pmUsers);
  const dhusers = useSelector((state) => state.dhUsersReducer.dhUsers);
  const DhTeam = useSelector((state) => state.teamSlice.teamByDhfilter);
  const areaslist = useSelector((state) => state.areasSlice.areas);
  const [disableTeam, setDisableTeam] = useState(true);
  const [loading, setLoading] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const [pmId, setPmId] = useState("");
  const [dhId, setDhId] = useState("");
  const [areaId, setAreaId] = useState("");
  const [teamId, setTeamID] = useState("");
  const [page, setPage] = useState(1);
  const role_id = localStorage.getItem("role_id");
  var roleName = "";
  if (role_id == "1") {
    roleName = "superadmin";
  } else if (role_id == "2") {
    roleName = "pm";
  } else if (role_id == "3") {
    roleName = "dh";
  } else if (role_id == "6") {
    roleName = "monitor";
  }

  useEffect(() => {
    dispatch(
      getDitricts({
        pageNumber: page,
        dhId: dhId,
        pmId: pmId,
        arId: areaId,
        tmId: teamId,
        sort_by: sortBy,
        sort_direction: sortDirection,
      })
    )
      .unwrap()
      .then((data) => {
        setIsLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        // console.error(error);
      });
    dispatch(getDhUsers())
      .unwrap()
      .then((data) => {
        // setIsLoading(false);
      })
      .catch((error) => {
        // console.error(error);
      });
    dispatch(getPmUsers())
      .unwrap()
      .then((data) => {
        // setIsLoading(false);
      })
      .catch((error) => {
        // console.error(error);
      });
    dispatch(getAreas({ pageNumber: "", dhId: "" }))
      .unwrap()
      .then((data) => {
        // setIsLoading(false);
      })
      .catch((error) => {
        // console.error(error);
      });
  }, [dispatch, page]);

  const handledelete = (id) => {
    // Logic to handle editing the row with the given id
    // console.log(`Editing row with ID: ${id}`);
    confirmAlert({
      title: "تاكيد الحذف",
      message: "هل انت متاكد من ذلك",
      buttons: [
        {
          label: "تاكيد",
          onClick: () => {
            setLoading(true);
            dispatch(deleteDitrict(id))
              .unwrap()
              .then((data) => {
                setLoading(false);
                toast.success("تم المسح بنجاح");
              })
              .catch((error) => {
                setLoading(false);
                toast.error(error.message);
              });
          },
        },
        {
          label: "الغاء",
        },
      ],
    });
  };

  const handledhChange = (event, value) => {
    dispatch(getTeamByDh(value));
  };

  const handelHistoryDialog = (placeId) => {
    setLoading(true);
    dispatch(getHistory({ place: "district", placeId: placeId }))
      .unwrap()
      .then((data) => {
        setOpenDialog(true);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  const handlepageChange = (event, value) => {
    setPage(value);
    setLoading(true);
  };

  const coulmns = [
    { field: "id", headerName: "الرقم التعريفي", width: 100 },
    { field: "code", headerName: "الرمز البريدي", editable: false, width: 180 },
    {
      field: "buildings_count",
      headerName: "عدد اللوحات",
      editable: false,
      width: 180,
    },
    {
      field: "implemented_building_count",
      headerName: "اجمالي اللوحات التي تم تركيبها",
      editable: false,
      width: 200,
    },
    {
      field: "assignedDHDepartmentHead",
      headerName: "رئيس القسم",
      editable: false,
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">
          {params.row.assignedDH != null
            ? params.row.assignedDH?.distributed_by?.name
            : ""}
        </div>
      ),
    },
  ];

  if (role_id == "6") {
    coulmns.push({
      field: "city",
      headerName: "المدينه",
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">{params.row?.city?.name_ar}</div>
      ),
    });
  }
  if (role_id == "2") {
    coulmns.push({
      field: "city",
      headerName: "المدينه",
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">{params.row?.city?.name_ar}</div>
      ),
    });
  }

  if (role_id !== "3") {
    coulmns.push({
      field: "is_assigned",
      headerName: "حالة التوزيع",
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">
          {params.row.is_assigned_level1 ? "موزعه" : "غير موزعه"}
        </div>
      ),
    });
  }

  if (role_id == "3") {
    coulmns.push({
      field: "is_assigned",
      headerName: "حالة التوزيع",
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">
          {params.row.is_assigned_level2 ? "موزعه" : "غير موزعه"}
        </div>
      ),
    });
  }

  if (role_id !== "4") {
    coulmns.push({
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div className="tableoptions">
          {role_id == "6" ? (
            ""
          ) : (
            <span className="editicon">
              <Link to={`/${roleName}/edit-district/${params.row.id}`}>
                <CreateIcon style={{ cursor: "pointer" }} />
              </Link>
            </span>
          )}

          <span className="historyicon">
            <HistoryIcon
              onClick={() => handelHistoryDialog(params.row.id)}
            ></HistoryIcon>
          </span>
          {/* <span className="deleteicon">
            <Delete
              onClick={() => handledelete(params.row.id)}
              style={{ cursor: "pointer" }}
            />
          </span> */}
        </div>
      ),
    });
  }

  if (role_id == "2") {
    coulmns.push(
      {
        field: "assignedDHsupervisor",
        headerName: "المشرف",
        width: 200,
        renderCell: (params) => (
          <div className="tableoptions">
            {params.row.assignedDH != null
              ? params.row.assignedDH?.team?.supervisor?.name
              : ""}
          </div>
        ),
      },
      {
        field: "unimplemented_building_count",
        headerName: "لوحات قيد الانتظار",
        width: 200,
      },
      {
        field: "polygon",
        headerName: "عرض المنطقة",
        width: 150,
        renderCell: (params) => (
          <div className="tableoptions">
            <span className="infoicon">
              <Link to={`/pm/district-polygan/${params.row.id}`}>
                <RoomOutlinedIcon style={{ cursor: "pointer" }} />
              </Link>
            </span>
          </div>
        ),
      }
    );
  }

  if (role_id == "6") {
    coulmns.push({
      field: "polygon",
      headerName: "عرض المنطقة",
      width: 150,
      renderCell: (params) => (
        <div className="tableoptions">
          <span className="infoicon">
            <Link to={`/monitor/district-polygan/${params.row.id}`}>
              <RoomOutlinedIcon style={{ cursor: "pointer" }} />
            </Link>
          </span>
        </div>
      ),
    });
  }

  if (isLoading) return <Loading open={true} />;

  return (
    <>
      {loading ? <Loading open={true} /> : null}

      <>
        <HistoryDialog
          historiews={historiews}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          handleClickOpen={handleClickOpen}
        />

        <div className="manage_employee plates_list building_table_page custom_pagination_table2">
          {role_id == "3" ? (
            ""
          ) : (
            <div className="add_button">
              {/* <Link to={`/${roleName}/add-new-ditrict`}>اضافة منطقه</Link> */}
              <Link to={`/${roleName}/add-ditrict-kml`}>
                اضافة ملف بالمناطق
              </Link>
            </div>
          )}

          <Grid container spacing={8} className="algin-center">
            <Grid sm={12} xs={12} md={11}>
              <div className="table_show table_style">
                <h5>قائمة المناطق</h5>

                <div className="filter">
                  {role_id != 3 && role_id != 6 ? <h5>تصفيه</h5> : null}
                  {/* {? <h5>تصفيه</h5> : null} */}

                  <form action="">
                    {/* filter super */}
                    {role_id == 1 ? (
                      <>
                        <div className="form-group">
                          <select
                            name=""
                            value={pmId}
                            onChange={(e) => {
                              setPmId(e.target.value);
                            }}
                            id=""
                            className="form-control"
                          >
                            <option defaultValue="0">اختر مدير المشروع</option>
                            {pmusers.map((pm) => (
                              <option value={pm.id} key={pm.id}>
                                {pm.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group">
                          <select
                            name=""
                            value={dhId}
                            onChange={(e) => {
                              setDhId(e.target.value);
                              if (e.target.value == "اختر رئيس القسم") {
                                setDhId("");
                                setDisableTeam(true);
                              }
                              setTeamID("");
                              setLoading(true);
                              dispatch(getTeamByDhFilter(e.target.value))
                                .unwrap()
                                .then((data) => {
                                  setLoading(false);
                                  setDisableTeam(false);
                                });
                            }}
                            id=""
                            className="form-control"
                          >
                            <option defaultValue="0">اختر رئيس القسم</option>
                            {dhusers.map((dh) => (
                              <option value={dh.id} key={dh.id}>
                                {dh.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group">
                          <select
                            name=""
                            value={teamId}
                            disabled={disableTeam}
                            onChange={(e) => {
                              setTeamID(e.target.value);
                              if (e.target.value == "اختر التيم") {
                                setTeamID("");
                              }
                            }}
                            id=""
                            className="form-control"
                          >
                            <option defaultValue="0">
                              {disableTeam ? "" : "اختر التيم"}
                            </option>
                            {DhTeam.map((tm) => (
                              <option value={tm.id} key={tm.id}>
                                {tm.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : null}

                    {/* filter super */}
                    {role_id == 2 ? (
                      <>
                        <div className="form-group">
                          <select
                            name=""
                            value={dhId}
                            onChange={(e) => {
                              setDhId(e.target.value);
                              setTeamID("");
                              setLoading(true);
                              dispatch(getTeamByDhFilter(e.target.value))
                                .unwrap()
                                .then((data) => {
                                  setLoading(false);
                                  setDisableTeam(false);
                                });
                            }}
                            id=""
                            className="form-control"
                          >
                            <option defaultValue="0">اختر رئيس القسم</option>
                            {dhusers.map((dh) => (
                              <option value={dh.id} key={dh.id}>
                                {dh.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* team */}
                      </>
                    ) : null}

                    {/* filter super */}
                    {/* {role_id == 3 ? (
                        <>
                          <div className="form-group">
                            <select
                              name=""
                              value={pmId}
                              onChange={(e) => {
                                // setPmId(e.target.value);
                              }}
                              id=""
                              className="form-control"
                            >
                              <option value="" selected>
                                اختر التيم
                              </option>

                            </select>
                          </div>
                        </>
                      ) : null} */}

                    {role_id != 3 && role_id != 6 ? (
                      <div className="btn_submit">
                        <button
                          className="btn"
                          onClick={(e) => {
                            e.preventDefault();
                            console.log(dhId, pmId, teamId);
                            setIsLoading(true);
                            dispatch(
                              getDitricts({
                                pageNumber: 1,
                                dhId: dhId,
                                pmId: pmId,
                                arId: areaId,
                                tmId: teamId,
                                sort_by: sortBy,
                                sort_direction: sortDirection,
                              })
                            )
                              .unwrap()
                              .then((data) => {
                                setIsLoading(false);
                              });
                          }}
                        >
                          بحث
                        </button>
                      </div>
                    ) : null}
                  </form>

                  <h5 style={{ marginTop: "15px" }}>الترتيب</h5>
                  <form action="">
                    <div className="form-group">
                      <select
                        name=""
                        value={sortBy}
                        onChange={(e) => {
                          setSortBy(e.target.value);
                          if (e.target.value == "ترتيب حسب") {
                            setSortBy("");
                          }
                        }}
                        id=""
                        className="form-control"
                      >
                        <option defaultValue="0">ترتيب حسب</option>
                        <option value="id">الرقم التعريفي</option>
                        <option value="code">الرمز البريدي</option>
                        <option value="buildings_count">عدد اللوحات</option>
                        <option value="implemented_building_count">عدد اللوحات المنفذه</option>
                        <option value="distributed_by">رئيس القسم</option>
                        <option value="city">المدينه</option>
                        <option value="is_assigned">حالة التوزيع</option>
                        <option value="supervisor">المشرف</option>
                        <option value="unimplemented_building_count">لوحات قيد الانتظار</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <select
                        name=""
                        value={sortDirection}
                        onChange={(e) => {
                          setSortDirection(e.target.value);
                          if (e.target.value == "نوع الترتيب") {
                            setSortDirection("");
                          }
                        }}
                        id=""
                        className="form-control"
                      >
                        <option defaultValue="0"> نوع الترتيب </option>
                        <option value="asc">تصاعدي</option>
                        <option value="desc">تنازلي</option>
                      </select>
                    </div>

                    <div className="btn_submit">
                      <button
                        className="btn"
                        onClick={(e) => {
                          e.preventDefault();
                          console.log(dhId, pmId, teamId);
                          setLoading(true);
                          dispatch(
                            getDitricts({
                              pageNumber: 1,
                              dhId: dhId,
                              pmId: pmId,
                              arId: areaId,
                              tmId: teamId,
                              sort_by: sortBy,
                              sort_direction: sortDirection,
                            })
                          )
                            .unwrap()
                            .then((data) => {
                              setLoading(false);
                            });
                        }}
                      >
                        بحث
                      </button>
                    </div>
                  </form>
                </div>

                <button
                  onClick={() => {
                    setLoading(true);
                    dispatch(
                      getDitricts({
                        pageNumber: 1,
                        dhId: "",
                        pmId: "",
                        arId: "",
                        tmId: "",
                        sort_by: "",
                        sort_direction: "",
                      })
                    )
                      .unwrap()
                      .then((data) => {
                        // setIsLoading(false);
                        setLoading(false);
                        setDisableTeam(true);
                        setTeamID("");
                        setDhId("");
                        setPmId("");
                        setSortBy("");
                        setSortDirection("");
                      });
                  }}
                  className="showall"
                >
                  عرض الكل
                </button>

                {role_id == "6" ? (
                  <div className="btn_submit">
                    <Link className="exportcsv" to="/monitor/allpolygans">
                      عرض المناطق على الخريطه
                    </Link>
                  </div>
                ) : null}

                <DataGridDemo
                  coulmns={coulmns}
                  rows={ditricts}
                  showFilter={true}
                />

                <TablePaginaiton
                  totlepages={totlepages}
                  page={page}
                  setPage={setPage}
                  handlepageChange={handlepageChange}
                />
              </div>
            </Grid>
            <Grid xs={3}>
              {/* <div className="options_buttons">
              <ul className="list-unstyled">
                <li>
                  <a href="">الاحياء الموزعه</a>
                </li>
                <li>
                  <a href="">الاحياء الغير موزعه</a>
                </li>
              </ul>
            </div> */}
            </Grid>
          </Grid>
        </div>
      </>
    </>
  );
}

export default DistrictsList;
